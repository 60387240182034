<template>
  <div class="stations">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col class="text-right">
        <v-btn
          v-if="can('meter-station-create')"
          color="primary"
          class="mb-5"
          @click="showAddStationDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiHomeGroup }}
          </v-icon>
          Add Station
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-row
          justify="center"
          class="mb-0"
        >
          <v-col class="col-md-6 text-center">
            <search-input
              v-model="search"
              :filters="searchFilters"
              :active-filter="activeSearchFilter"
              @filter-change="onSearchFilterChange"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="stations"
        :loading="isMetersLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="stations.length > 0">
            <tr
              v-for="station in items"
              :key="station.id"
            >
              <td>{{ station.name }}</td>
              <td>{{ station.location }}</td>
              <td>{{ station.paybill_number }}</td>
              <td>{{ station.meters_count }}</td>
              <td
                v-if="can('meter-station-edit') || can('meter-station-delete')"
                @click.stop
              >
                <v-tooltip
                  v-if="can('meter-station-edit')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="editStation(station)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip
                  v-if="can('meter-station-delete')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteStationDialog(station.id)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isMetersLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No stations found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <v-pagination
        v-model="pagination.page"
        class="mt-1"
        :length="pagination.pageCount"
        :total-visible="8"
        @input="onPageChange"
      ></v-pagination>
    </v-card>
    <add-station-dialog
      :show-dialog="showAddStationDialog"
      :station="station"
      @close="
        showAddStationDialog = false
        station = {}
      "
      @station-added="getStations()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteMeter()"
    />
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiHomeGroup,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import AddStationDialog from '@/components/dialogs/AddStationDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'

export default {
  components: {
    AddStationDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
  },
  mixins: [hasPermission],
  data() {
    return {
      showAddStationDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: null,
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: 'Name',
          align: 'name',
          sortable: false,
        },
        {
          text: 'Location',
          align: 'location',
          sortable: false,
        },
        {
          text: 'Paybill Number',
          align: 'name',
          sortable: false,
        },
        {
          text: 'Meters count',
          value: 'meter_count',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'name',
      searchFilters: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Paybill Number',
          value: 'paybill_number',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiHomeGroup,
      },
      station: {},
      stationId: this.$route.params.id,
      stations: [],
      isMetersLoading: true,
      stationsUrl: 'meter-stations',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Stations',
          disabled: false,
          to: { name: 'stations' },
        },
      ],
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.stationUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getStations()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getStations()
      }
    },
  },
  mounted() {
    if (!(this.can('meter-station-edit') && this.can('meter-station-delete'))) {
      this.removeActionsHeader()
    }
    this.getStations()
  },
  methods: {
    ...mapActions(['refreshStations']),
    getStations: _.debounce(function () {
      this.isMetersLoading = true
      axios
        .get(
          `${this.stationsUrl}?paginate=true&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}`,
        )
        .then(response => {
          this.stations = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isMetersLoading = false
        })
        .catch(error => {
          this.isMetersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    editStation(station) {
      this.station = station
      this.showAddStationDialog = true
    },
    showDeleteStationDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteMeter() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`meter-stations/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Meter station deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getStations()
          this.refreshStations(true)
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onPageChange() {
      this.getStations()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
  },
}
</script>
